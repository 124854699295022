.createNftWholePage{
    display: block;
}
.createNftPage{
    display: flex;
}

.createNftPagePart1{
    max-width: 394px;
    margin-top: 100px;
    margin-right: 32px;
    width: 100%;
}

.nftCreate {
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #0F283D;
    margin-bottom: 14px;
}

.nameNftDiv{
    display:flex;
    flex-direction: column;
    margin-bottom: 18px;
}

.wordDiv{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #0F283D;
    margin-bottom: 6px;
}

.nameNftInput{
    padding: 12px 0px 12px 12px;
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #A5A7A8;
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
}

.selCateg{
    color: #0F283D;
}


.memo{
    height: 80px;
    margin-bottom: 0;
}

.createNftPagePart2{
    background: #FFFFFF;
    border: 1px dashed #DBDBDB;
    border-radius: 6px;
    margin-bottom: 30px;
    margin-top: 162px;
    height: 591px;
    max-width: 394px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createNftPagePart2:hover{
    border: 1px dashed #03B4F8;
}


.nameNftInput:focus{
    outline: none;
    border: 1px solid #03B4F8;
}

.createNftPagePart2Div{
    display: block;
    text-align: center;
    cursor: pointer;
}


.cloudImage{
    width: 67px;
    height: 45px;
    object-fit: contain;
}

.dropDiv{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color:  #0F283D;
    margin-top: 16px;
    margin-bottom: 6px;
}

.unploadDiv{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.015em;
    text-decoration-line: underline;
    color: #03B4F8;
}

.downVectorClass{
    width: 10px;
    height: 7px;
    position: absolute;
    top: 17px;
    right: 21px;
}


.selectCategoryDiv{
    position: relative;
    width:100%;
}

.cancelCreateNft{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 100px;
}

.cancelBtnNft{
    color: #037BF3;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    margin-right: 8px;
}


.createBtnNft{
    color: #FFFFFF;
    background: linear-gradient(98.28deg, #037BF3 2.98%, #03B4F8 79.09%);
}

.text-danger-nft{
    color:red;
    font-size:16px;
}

.nftLabelName{
    cursor: pointer;
}

.uploadedImageNft{
    width: 100%;
    height: 591px;
    display: flex;
    object-fit: cover;
}

.nftPicInput{
    display: none;
}

.optionNameDiv{
    font-family: RobotoRegular !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #0F283D;
    padding: 14px;
    border-bottom: 1px solid #D6D6D6;
}