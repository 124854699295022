.qrBox{
    background: #FFFFFF;
    border-radius: 6px;
}

.qrDiv1{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.qrDiv2{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #03B4F8;
    margin-right: 13px;
}

.qrIconImg{
    width: 20px;
    height: 20px;
}

.qrDiv3{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #5B5B5B;
    text-align: center;
    margin-bottom: 75px;
}

.qrDiv4{
    width: 150px;
    height: 150px;
    margin: 0 auto;
}

.qrScanImg{
    width: 100%;
    height: 100%;
}