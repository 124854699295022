
.commentsXpoem{
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.commentsDivXpoem{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
.myPageXpoem .commentsmyPage:last-child{
    margin-bottom: 100px;
}

.TipButtonXpoem{
    color: #0F283D;
    line-height: 28px;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
}

.userPicXpoem{
    width: 60px;
    height: 60px;
    margin-left: 16px;
    margin-right: 16px;
}

.commentsDivNameXpoem{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color:  #0F283D;
    margin-right: 16px;
}

.commentsDivDateXpoem{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #414141;
}

.commentsDescriptionXpoem{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #414141;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 562px;
}

.commentsSmallDivXpoem{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
}


.likeReplyWholePartXpoem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.likeReplyWholePart1Xpoem{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #03B4F8;
    cursor: pointer;
}

.likeReplyWholePart2Xpoem {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.likeImgCommentsPartXpoem{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.likeImgCommentsPartBlueXpoem{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.likeReplyPartXpoem{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #03B4F8;
    cursor: pointer;
}

.likeReplyWholePart2SmallDivXpoem{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.replyXpoem{
    margin-left: 40px;
}
