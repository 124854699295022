.UpdateProfileModalDiv1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.UpdateProfileModalDiv2{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #0F283D;
}

.UpdateProfileModalDiv3{
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.UpdateProfileModalLastDiv{
    background: #FFFFFF;
    border: 1px dashed #0F283D;
    border-radius: 6px;
    height: 374px;
    display: flex;
    align-items: center;
    justify-content: center;
    }

.UpdateProfileModalLastDivPart{
    display: block;
    text-align: center;
    cursor: pointer;
}

.cloudImageDiv{
    width: 67px;
    height: 45px;
    object-fit: contain;
}

.dropDivUpload{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color:  #0F283D;
    margin-top: 16px;
    margin-bottom: 6px;
}

.unploadDivPart{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.015em;
    text-decoration-line: underline;
    color: #03B4F8;
}


.updatePicInput{
    display: none;
}
