.createXpoemWholePage{
    display: block;
}

.createXpoemPage{
    display: flex;
}

.createXpoemPagePart1{
    max-width: 394px;
    margin-top: 100px;
    margin-right: 32px;
    width: 100%;
}

.xpoemCreate{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #0F283D;
    margin-bottom: 14px;
}

.nameNftDiv{
    display:flex;
    flex-direction: column;
    margin-bottom: 18px;
}

.wordDiv{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #0F283D;
    margin-bottom: 6px;
}

.nameNftInput{
    padding: 12px 0px 12px 12px;
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #A5A7A8;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
}

.selectCategoryDiv{
    position: relative;
    width:100%;
}

.downVectorClass{
    width: 10px;
    height: 7px;
    position: absolute;
    top: 17px;
    right: 21px;
}

.authorNotes{
    height: 80px;
}

.selCateg{
    color: #0F283D;
    cursor: pointer;
}

.createXpoemPagePart2{
    max-width: 394px;
    width: 100%;
    margin-top: 137px;
}

.yourXpoems{
    height: 165px;
}

.cancelCreateXpoem{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 200px;
    margin-top: 30px;
}

.cancelBtnXpoem{
    color: #037BF3;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    margin-right: 8px;
}

.CreateBtnXpoem{
    color: #FFFFFF;
    background: linear-gradient(98.28deg, #037BF3 2.98%, #03B4F8 79.09%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-danger-xpoem{
    color:red;
    font-size:16px;
}
