.profile{
    max-width: 1040px;
    margin: 0 auto;
}

.coverDiv{
    margin-top: 40px;
    max-width: 1040px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.uploadedCoverImage{
    margin-top: 40px;
    max-width: 1040px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 240px;
    object-fit: cover;
}

.profileLabelName{
    cursor: pointer;
}

.profileImg{
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: -100px;
    cursor: pointer;
}

.profileInput{
    display: none;
}

.camImg{
    width: 46px;
    height: 46px;
    position: absolute;
    transform: translateX(-50%);
    right: 40%;
    bottom: -90px;
    cursor: pointer;
}

.uploadedImage{
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: -100px;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    border: 8px solid #FFFFFF;
}



.profileName{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    color: #0F283D;
    margin-bottom: 6px;
    text-align: center;
    margin-top: 110px;
}

.profileAddress{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #03B4F8;
    margin-bottom: 24px;
    text-align: center;
}

.follow{
    display: flex;
    justify-content: center;
    align-items: center;

}

.followings{
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
}

.followNum{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #0F283D;
    text-align: center;
}

.followWord{
    font-family: RobotoLight;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #0F283D;
}


.profileDate{
    font-family: RobotoLight;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 28px;
    color: #414141;
    text-align: center;
}

.profileDescription{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #414141;
    text-align: center;
    max-width: 712px;
    margin: 30px auto 6px;
}

.myProfileDiv{
    display: flex;
    background: #FFFFFF;
    border: 1px solid #18A0FB;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 50px;
}

.myProfileDivs{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #0F283D;
    border-right:1px solid #18A0FB;
    padding: 10px 0;
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coverLabelName{
    cursor: pointer;
}

.part{
    background: linear-gradient(
            91.02deg
            , #037BF3 5.02%, #03B4F8 85.45%);
    color: #FFFFFF;
}

.coverInput{
    display: none;
}




.lastPart{
    position: relative;
    border-right: none;
}


.allPartVector{
    width: 10px;
    height: 6px;
    margin-left: 11px;
}

.allPartVectorDownWhite{
    width: 10px;
    height: 6px;
    margin-left: 11px;
}

.allPartVectorUp{
    margin-left: 11px;
    width: 10px;
    height: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.dropdownAll{
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.18);
    border-radius: 0px 0px 6px 6px;
    top: 55px;
    width: 274px;
    z-index: 1;
}


.dropdownAll>ul>li{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #0F283D;
    padding: 16px 0;
    z-index: 1;
    border-bottom: 1px solid #D6D6D6;
}


.myProfileDiv2{
    display: flex;
    margin-bottom: 25px;
    margin-top: 30px;
}

.myProfileDiv2Part{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #0F283D;
    margin-right: 51px;
    display: flex;
    align-items: center;
}


.pinImg{
    width: 24px;
    height: 24px;
    margin-right: 7px;
}



.myProfileDiv3{
    display: flex;
}


.myProfileDiv3Part1{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #03B4F8;
    margin-right: 57px;
    margin-left: 30px;
}
 .poemRows{
    max-width: 500px ;
    width: 100%;
    padding: 40px;
    background: rgba(229, 229, 229, 0.3);
    border-radius: 6px;
    margin-bottom: 40px;
    position: relative
}
.mediumOne:after {
    content: "";
    width: 1px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.18);
}
.mediumOne:before {
    content: "";
    width: 1px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.18);
}

.pinned2Img{
    position: absolute;
    top: 0;
    right: 0;

}

.poemRowsTitle{
    font-family: SitkaBold;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #0F283D;
    margin-bottom: 30px;
}

.poemRowsContent{
    font-family: SitkaRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #414141;
    margin-bottom: 27px;
}

.poemRowsPart{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.poemRowsPartDesc{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #03B4F8;
    margin-right: 6px;
}

.expandedDescription{
    margin-top: 27px;
    font-family: SitkaRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #414141;
}

.poemRowsPartSign{
    width: 13px;
    height: 13px;
}

.myProfileDiv4{
    margin-bottom: 70px;
}


.drop:hover{
    background: #F1F2F5;
}






