.poetPageDiv{
    margin-top: 30px;

}

.poemTitle{
    text-align: center;
    margin-top: 43px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
    ccolor: #0F283D;
    font-family: SitkaBold;
    font-style: normal;
    font-weight: normal;
}

.poemContent {
    margin: 0 auto 30px;
    text-align: center;
    max-width: 420px;
    font-size: 20px;
    line-height: 28px;
    color: #414141;
    font-family: SitkaRegular;
    font-style: normal;
    font-weight: normal;
    word-break: break-word;
}

.addedReadMore{
    margin: 30px auto 30px;
    text-align: center;
    max-width: 420px;
    font-size: 20px;
    line-height: 28px;
    color: #414141;
    font-family: SitkaRegular;
    font-style: normal;
    font-weight: normal;
}

.readMoreDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.readMore{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color:  #03B4F8;
    margin-right: 11px;
}

.NextSignImg{
    width: 13px;
    height: 13px;
}

