
.comments{
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.commentsDiv{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
.myPage .comments:last-child{
    margin-bottom: 100px;
}

.TipButton{
    color: #0F283D;
    line-height: 28px;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
}

.userPic{
    width: 60px;
    height: 60px;
    margin-left: 16px;
    margin-right: 16px;
}

.commentsDivName{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color:  #0F283D;
    margin-right: 16px;
}

.commentsDivDate{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #414141;
}

.commentsDescription{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #414141;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 562px;
}

.commentsSmallDiv{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
}


.likeReplyWholePart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.likeReplyWholePart1{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #03B4F8;
    cursor: pointer;
}

.likeReplyWholePart2 {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.likeImgCommentsPart{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.likeImgCommentsPart{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.likeReplyPart{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #03B4F8;
    cursor: pointer;
}

.likeReplyWholePart2SmallDiv{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.reply{
    margin-left: 40px;
}

.replyingDiv{
    max-width: 562px;
    width: 100%;
    align-content:end;
    margin-right: 0px;
}


.replyingDiv1{
    display: flex;
    margin-top: 10px;
    margin-bottom: 16px;
}

.userPicReply{
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.replyingDiv2{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0F283D;
    display: flex;
    align-items: center;
}

.replyingDiv3{
    max-width: 562px;
    width: 100%;
    height: 160px;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    padding:  16px 54px 16px 16px;
}

.replyingDiv4{
    width: 100%;
    border: 0;
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #414141;
    outline: 0;
    height: 100%;
    resize: none;
}

.showImgMyPageReply{
    width: 24px;
    height: 24px;
    margin-right: 10px;
    position: absolute;
    right: 16px;
    bottom: 18px;
    cursor: pointer;
}





