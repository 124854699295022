.ProfilePictureModalDiv1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ProfilePictureModalDiv2{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #0F283D;
}

.ProfilePictureModalDiv3{
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.ProfilePictureModalMiddleDiv{
    margin: 24px auto 34px;
    width: 280px;
    height: 280px;
}

.ProfilePictureModalDiv4{
    width:100%
}

.ProfilePictureModalLastDiv{
    display: flex;
    justify-content: space-between;
}
.ProfilePictureModalLastDivPart{
    display: flex;
}

.ProfilePictureModalDiv5{
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.ProfilePictureModalDiv5:first-child{
    margin-right: 54px;
}

.ProfilePictureModalDiv6{
    width: 24px;
    height: 24px;
    margin: auto;
}

.ProfilePictureModalDiv7{
    margin-top: 9px;
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #0F283D;
}