.allUnreadDiv{
    display: flex;
    margin-bottom: 20px;
}

.allDiv{
    width: 54px;
    padding: 5px 18px;
    background: linear-gradient(111.19deg, #037BF3 6.67%, #03B4F8 91.99%);
    border-radius: 4px;
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.unreadDiv{
    width: 82px;
    padding: 5px 18px;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #03B4F8;
    cursor: pointer;
}

