.personalInformationDiv1{
    display: flex;
}

.personalInformationDiv2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.poetImg{
    max-width: 44px;
}

.personalInformationDiv3{
    margin-left: 8px;
}

.personalInformationDiv4{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0F283D;
}

.personalInformationDiv5{
    display: flex;
    margin-top: 4px;
}

.personalInformationDiv6{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #414141;
    margin-right: 10px;
}

.personalInformationDiv7{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #03B4F8;
    cursor: pointer;
}