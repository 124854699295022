.myPageSmallDiv{
    display: flex;
    align-items: center;
    margin-top: 100px;
}


.XWordHeader{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;
    color: #18A0FB;
    margin-right:0;
    margin-top: -6px;
}

.myPageSmallDivPart2{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    text-align: center;
    color: #03B4F8;
    margin-right: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
}

.myPageSmallDivPart2:first-child{
    margin-right: 50px;
    margin-left: 10px;
}
.myPageSmallDivPart2:last-child{
    margin-right: 0px;
}

.activeUnderlined{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #03B4F8;
}

.activeUnderlined:after {
    content: '';
    display: block;
    width: 115%;
    position: absolute;
    border: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    border-bottom: 2px solid #18A0FB;
}


