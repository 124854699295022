.MyPageXpoemDiv1{
    margin-top: 48px;
    margin-bottom: 25px;
}

.MyPageXpoemDiv2{
    max-width: 420px;
    text-align: center;
    font-family: SitkaBold;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #0F283D;
    margin: 0 auto 30px;
}

.MyPageXpoemDiv3{
    max-width: 420px;
    font-family: SitkaRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #0F283D;
    margin: 0 auto 24px;
}

.MyPageXpoemDiv4{
    margin-top: 40px;
    margin-bottom: 30px;
}
.MyPageXpoemDiv5{
    margin-bottom: 102px;

}