.myPageButtons{
    display: flex;
    align-items: flex-start;
}

.buyNowBtnMyPage{
    display:flex;
    line-height: 28px;
    color: #FFFFFF;
    background: linear-gradient(284.85deg, #04B5F8 14.1%, #037BF3 79.27%);
    white-space: nowrap;
}

.buyNowBtnMyPage:hover{
    background: linear-gradient(113.61deg, #03B4F8 11.98%, #037BF3 96.01%);
    box-shadow: 0 8px 24px #E3E3E3;
    border-radius: 6px;
}

.buyButtonImgMyPage{
    width:24px;
    height: 24px;
    margin-right: 8px;
}

.likeBtnMyPage{
    display:flex;
    color: #0F283D;
    line-height: 28px;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    margin-right: 16px;
    margin-left: 16px;
}

.likeButtonImgMyPage{
    width:24px;
    height: 24px;
    margin-right: 8px;
}

.blueLike{
    width:24px;
    height: 24px;
    margin-right: 8px;
}

.commentInputDivMyPage{
    max-width: 562px;
    width: 100%;
    height: 160px;
    background: #FFFFFF;
    border: 1px solid #E5E8EB;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    padding:  16px 54px 16px 16px;
}
.commentInputDivMyPage:hover{
    border: 1px solid #03B4F8;
}

.commentInputMyPage{
    width: 100%;
    border: 0;
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #414141;
    outline: 0;
    height: 100%;
    resize: none;
}

.showImgMyPage{
    width: 24px;
    height: 24px;
    margin-right: 10px;
    position: absolute;
    right: 16px;
    bottom: 18px;
    cursor: pointer;
}