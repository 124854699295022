.notificationsDiv{
    margin-bottom: 20px;
}

.notificationsDiv1{
display: flex;
}

.UserPicNot{
    width: 60px;
    height: 60px;
    margin-right: 8px;
}

.nameNot{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3E5060;
}

.NotificationMessage{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3E5060;
    margin-right: 20px;
}

.notificationsDate{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #A5A7A8;
    margin-top: 5px;
}

.notificationUnread{
    margin-top: 25px;
    width: 10px;
    height: 10px;
}

