.walletBox{
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.walletDiv1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.walletDiv2{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #0F283D;
}

.walletCloseImg{
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.walletDiv3{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #5B5B5B;
    margin-bottom: 28px;
}

.walletDiv4{
    width:100%;
    max-width:400px;
    padding: 13px 16px;
    border:1px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 6px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.walletImg{
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.walletDiv5{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #0F283D;
}