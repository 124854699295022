.redirectPageBigDiv{
    display: flex;
    margin-top: 100px;
    margin-bottom: 60px;
}

.redirectPageDiv2{
    width: 312px;
    height: 369px;
    background: #E5E5E5;
    border-radius: 6px;
    margin-bottom: 24px;
}

.redirectPageDiv3{
    display: flex;
    align-items: center;
    width: 312px;
    padding: 12px 24px 12px 17px;
    background: #FFFFFF;
    border-bottom: 1px solid #03B4F8;
    box-sizing: border-box;
    justify-content: space-between;
    cursor: pointer;
}



.redirectPageDiv3Part{
    display: flex;
    align-items: center;
}

.redirectPageDivIcon1{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    object-fit: contain;
}

.redirectPageDivIcon2{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    object-fit: contain;
}

.redirectPageDivIcon3{
    width: 20px;
    height: 16px;
    margin-right: 8px;
    object-fit: contain;
}
.redirectPageDiv4{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #0F283D;
}


.redirectPageDivImg{
    Width: 10px;
    Height: 6px;
}

.redirectPageDivPart2{
    margin-left: 24px;
}

.redirectPageDivPart2{
    width: 486px;
}

.redirectPageDivPart2Header{
    display: flex;
    justify-content: space-between;
}

.redirectPageDivPart2Header1{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.8);
}

.redirectPageDivIcon4{
    width: 18px;
    height: 20px;
    margin-right: 24px;
}

.redirectPageDivIcon5{
    width: 15px;
    height: 17px;
}

.redirectPageDiv5{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #414141;
    margin-top: 10px;
    margin-bottom: 40px;
}

.redirectPageDiv6{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #0F283D;
    margin-bottom: 17px;
}

.redirectPageDiv7{
    display: flex;
}

.redirectPageDiv8{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #414141;
    margin-right: 4px;
}

.redirectPageDiv9{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
    color: #414141;
}

.redirectPageBtns{
    display: flex;
    margin-top: 30px;
    margin-bottom: 50px;
}

.redirectPageBtn1{
    width: 200px;
    padding: 6px 0;
    text-align: center;
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    cursor:pointer;
    background: linear-gradient(99.15deg, #037BF3 17.29%, #03B4F8 68.42%);
    border: 1px solid #18A0FB;
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 16px;
}

.redirectPageBtn2{
    width: 200px;
    padding: 6px 0;
    text-align: center;
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #037BF3;
    cursor:pointer;
    background: #FFFFFF;
    border: 1px solid #18A0FB;
    box-sizing: border-box;
    border-radius: 6px;
}

.place{
    width: 486px;
    border: 1px solid #18A0FB;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
}

.bigger{
    display: flex;
    align-items: center;
    width: 486px;
    padding: 12px 24px 12px 17px;
    background: #FFFFFF;
    box-sizing: border-box;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: none;
}

.redirectPageDivIcon6{
    width: 22px;
    height: 12px;
    margin-right: 7px;
}

.redirectPageDivIcon7{
    width: 20px;
    height: 20px;
    margin-right: 7px;
}
.bigPlace{
    width: 820px;
    border: 1px solid #18A0FB;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
}

.biggest{
    width: 820px;
}

.redirectPageDivIcon8{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.redirectPageDivIcon9{
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.redirectPageFooter{
    margin-top: 50px;
}

.redirectPageDivs{
    border-radius: 6px;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    overflow:hidden;
}

.commonDiv{
    padding: 16px;
    background: #FAFDFF;
    overflow-y: auto;
    border-bottom: 1px solid #18A0FB;
    max-height: 152px;
    overflow-y: auto;
}

.DescriptionDiv1{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 6px;
}

.DescriptionDiv1Span{
    color: #03B4F8;
    font-family: RobotoRegular;

}

.DescriptionDiv2{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    ccolor: #414141;
}

.aboutDiv1{
    display: flex;
    margin-bottom: 16px;
}

.aboutDiv2{
    width: 88px;
    height: 114px;
    background: #E5E5E5;
    margin-right: 10px;
}

.aboutDiv3{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color:#414141;
    width: 182px;
}

.footerIcon1{
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

.footerIcon2Div{
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color:  #5865F2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerIcon2{
    width: 22px;
    height: 22px;
}

.aboutDiv4{
    display: flex;
}


.detailsDiv1{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.detailsDiv2{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color:  #414141;
    width: 50%;
}

.end{
    text-align: end;
}

.blueOne{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #03B4F8;
}

.radius{
    border-bottom: none;
}

.radius1{
    border-bottom: none;
    border-top:  1px solid #18A0FB;
}

.showListings1{
    display: flex;
    max-width: 486px;
    width: 100%;
    border-top:  1px solid #03B4F8;
}

.showListings1Div{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #0F283D;
    width: 35%;
    padding: 10px 16px;
    border-bottom: 1px solid #03B4F8;
}


.showListings1Div:last-child{
    width: 30% ;
}

.showListings2{
    max-width: 486px;
    width: 100%;
}

.showListings2Part{
    display: flex;
}

.showListings2Div{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #000000;
    width: 35%;
    padding: 16px;
    border-bottom: 1px solid #E1E1E1;
}

.showListings2Div:last-child{
    width: 30%;
}


.blueColor {
    color: #2196F3;
}

.showListingsWholeDiv{
    background: #FAFDFF;
    box-sizing: border-box;
    max-width: 486px;
    width: 100%;
}

.ItemActivityWhole{

}

.ItemActivityWholePart{
    padding: 16px;
    background: #FCFEFF;
    border-top: 1px solid #03B4F8;
    border-bottom: 1px solid #03B4F8;
}

.ItemActivityFilterDiv{
    display: flex;
    padding: 6px 19px 6px 19px;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #03B4F8;
    align-items: center;
}

.ItemActivityFilterDivPart{
    display: flex;
}

.ItemActivityFilterImg{
    width: 22px;
    margin-right: 10px;
    object-fit: contain;
}

.ItemActivityFilterDivPart{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #414141;
}
.ItemActivityFilterVectorImg{
    width: 10px;
    height: 6px;
}

.itemActivity1{
    display: flex;
    justify-content: space-between;
    padding: 9px 32px 9px 32px;
    border-bottom: 1px solid #03B4F8;
}

.itemActivity2{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #0F283D;
    width: 25%;
    text-align: start;
}

.itemActivity2:nth-child(2){
    width: 15%;
}
.itemActivity2:last-child{
    width:10%
}



.itemActivity3{

}



.itemActivity4{
    display: flex;
    justify-content: space-between;
    padding: 18px 32px 18px 32px;
    border-bottom: 1px solid #E1E1E1;
}

.itemActivity4:last-child {
    border-bottom: none;
}
.itemActivity5{
    display: flex;
    width: 25%;
    text-align:  start;
}

.ItemActivityStyle{
    width: 20px;
    height: 20px;
    margin-right:10px;
}

.itemActivity6{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #414141;
}

.itemActivity7{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #414141;
    width: 15%;
    text-align:  start;
}

.itemActivity8{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #03B4F8;
    width: 25%;
    text-align:  start;
}

.itemActivity9{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #03B4F8;
    width: 25%;
    text-align: start;
}

.itemActivity10{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #414141;
    width: 10%;
    text-align: end
}

.collectionWholeDiv{
    padding: 16px;
    display: flex;
    border-top: 1px solid #03B4F8;
    width: 820px;
    overflow-x: auto;
}

.collectionDiv{
    min-width: 179px;
    height: 230px;
    margin-right:24px;
    background: #E5E5E5;
    border-radius: 6px;
}

.collectionDiv:last-child{
    margin-right:0;
}

.priceHistoryWholeDiv{
    padding: 16px 16px 25px 16px;
    border-top: 1px solid #03B4F8;
}

.priceHistoryDiv1{
    display: flex;
}

.priceHistoryDiv2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 10px 16px !important;
    border: 1px solid #03B4F8;
}

.priceHistoryDiv3{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #414141
}


.priceHistoryDiv4{
    margin-left: 30px;
}

.priceHistoryDiv5{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #03B4F8;
    margin-top: 6px;
}

.priceHistoryDiv6{
    margin-left: 20px;
}



