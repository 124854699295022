.footer{
    margin-bottom:74px;
}

.footerPart{
    display: flex;
    margin-bottom: 18px;
}

.footers1{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #03B4F8;
    margin-right: 20px;
}

.footerIcon2{
    width: 22px;
    height: 22px;
}

.footerIcon1{
    width: 22px;
    height: 18px;
    margin-right: 10px;
}

.footerIcon2Div{
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color:  #5865F2;
    display: flex;
    justify-content: center;
    align-items: center;
}


.XPoesyFooterImg{
    width: 67px;
    height: 33px;
    object-fit: contain;
    padding-bottom: 7px;
}


.footerPartMain{
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
    padding-bottom: 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
}
.footerPart1{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
}

.footerPart2{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color:  #0F283D;
    white-space: nowrap;
}

.noMargin{
    margin-right: 0;
}