.buttonsWholeDiv{
    display:flex;
    margin-top: 40px;
    border-bottom: 1px solid #D9DDE0;
    padding-bottom: 30px;
}

.BuyNowBtn{
    display:flex;
    align-items: center;
    line-height: 28px;
    color: #FFFFFF;
    background: linear-gradient(284.85deg, #04B5F8 14.1%, #037BF3 79.27%);
    white-space: nowrap;
    border: 1px solid #03B4F8;
}

.BuyNowBtn:hover{
    background: linear-gradient(113.61deg, #03B4F8 11.98%, #037BF3 96.01%);
    box-shadow: 0 8px 24px #E3E3E3;
    border-radius: 6px;
}

.BuyButtonImg{
    width:24px;
    height: 24px;
    margin-right: 8px;
}

.likeBtn{
    display:flex;
    align-items: center;
    color: #0F283D;
    line-height: 28px;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    margin-right: 16px;
    margin-left: 16px;
}


.LikeButtonImg{
    width:24px;
    height: 24px;
    margin-right: 8px;
    object-fit: contain;
}

.blueLikeContainer{
    width:24px;
    height: 24px;
    margin-right: 8px;
}

.commentInputDiv{
    max-width: 375px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    overflow: hidden;
    border:  1px solid #E5E8EB;
}

.commentInputDiv:hover{
    border: 1px solid #03B4F8;
}
.showImg{
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.commentInput{
    border: 0;
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #7F8A92;
    width: 100%;
    padding: 6px 0 6px 15px;
    background-color: #FFFFFF;
    outline: 0;
}

.numberBtn{
    display:flex;
    align-items: center;
    color: #0F283D;
    line-height: 28px;
    background: #FFFFFF;
    margin-right: 16px;
    margin-left: 16px;
    border: 1px solid #03B4F8;
}

.NumberButtonImg{
    width:24px;
    height: 24px;
    margin-right: 8px;
}

.TipButton{
    color: #0F283D;
    line-height: 28px;
    background: #FFFFFF;
    border: 1px solid #03B4F8;
}

.TipButton:hover{
    background: linear-gradient(113.61deg, #03B4F8 11.98%, #037BF3 96.01%);
    box-shadow: 0px 8px 24px #E3E3E3;
    border-radius: 6px;
    color: #FFFFFF;
}

.likeBtn:hover .LikeButtonImg{
    display: none;
}
.likeBtn:hover .blueLikeContainer{
    display: block;
}
.clicked .blueLikeContainer{
    display: block !important;
}
.clicked .LikeButtonImg{
    display: none !important;
}
.blueLikeContainer{
    display: none;
}