.homePage{
    display: flex;
    flex-direction: column;
}

.homeTitle{
    font-family: SitkaRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #0F283D;
    margin-top: 100px;
    margin-bottom: 46px;
}

.homeMenuDiv{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.homeMenuDivPart{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #03B4F8;
    margin-right: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 18px;
}

.XWord{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;
    color: #03B4F8;
    margin-right:0;
    margin-top: -6px;
    margin-left: 10px;
}

.active{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;

}

.active:after{
    content:'';
    display: block;
    width: 115%;
    position: absolute;
    border: 0;
    left: 60%;
    transform: translateX(-50%);
    height: 50px;
    border-bottom: 2px solid #18A0FB;
}

.lastactive{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

.nfts{
    text-transform: none;
}

.last{
    display: flex;
    white-space: nowrap;
    margin-right: 0px;
    position: relative;
}

.vectorPart{
    margin-left: 6px;
    display: flex;
    align-items: center;
    width: 10px;
    height: 6px;
}

.loadMoreBtn{
    margin-top: 60px;
    margin-bottom: 100px;
    color: #037BF3;
    background: #FFFFFF;
    border: 1px solid #037BF3;
    margin-right:auto;
    margin-left: auto;
}

.dropdown{
    position: absolute;
    top: 33px;
    background: #FFFFFF;
    border-radius: 0px 0px 6px 6px;
    width: 190px;
    border-top: 2px solid #03B4F8;
}

ul {
    padding: 0;
    margin: 0;
}
li{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    color: #0F283D;
    padding: 15px 20px;
    list-style: none;
    text-transform: capitalize;
    z-index: 1;
    border-bottom:  1px solid #D6D6D6;
}

li:last-child{
    border-bottom: none;
}

li:hover{
    background: #F1F2F5;
}

li:first-child:hover{
    border-top:none
}






