.popupBox{
    width: 304px;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    border-radius: 6px;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 1;
    box-shadow: 0px 6px 8px rgb(0 0 0 / 18%);
}

.popUpPhotos{
    margin: auto;
    position: relative;
    height:76px ;
    width: 76px;
    margin-top: 17px;
}

.PopUpImg{
    height:76px ;
    width: 76px;
}

.CameraImg{
    position: absolute;
    right: 3px;
    bottom: 0
}

.popIconAndText{
    display: flex;
    align-items: center;
    padding: 14px 15px;
    border-top:1px solid rgba(0, 0, 0, 0.12);
    cursor: pointer;
}
/*.popupBox .popIconAndText:nth-child(4){*/
/*   border-top: none;*/
/*}*/

.namePop{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
}

.popText{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-left: 11px;
}

.createXpoemIconImg{
    width: 22px;
    height: 22px;
}

.NFTImg{
    width: 18px;
    height: 18px;
}

.SettingsImg{
    width: 18px;
    height: 18px;
}

.LogOutImg{
    width: 16px;
    height: 16px;
}

.popIconAndText:hover{
    background: #F1F2F5;
}