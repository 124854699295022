
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.container-header{
  max-width: 1600px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #C4C4C4;
}

::-webkit-scrollbar
{
  width: 4px;
  background-color: #C4C4C4;
}

::-webkit-scrollbar-thumb
{
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}


.container-body{
  max-width: 850px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left:auto;
  margin-right:auto;
}

.btn {
  min-width: 130px;
  padding: 10px 0;
  text-align: center;
  font-family: RobotoMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-radius: 6px;
  line-height: 19px;
  cursor:pointer;
}

.btnSmall{
  min-width: 61px;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
}


@font-face {
  font-family: RobotoBold;
  src: url(./app/assets/fonts/roboto/Roboto_Bold.ttf);
}
@font-face {
  font-family: RobotoMedium;
  src: url(./app/assets/fonts/roboto/Roboto_Medium.ttf);
}

@font-face {
  font-family: RobotoRegular;
  src: url(./app/assets/fonts/roboto/Roboto_Regular.ttf);
}

@font-face {
  font-family: RobotoLight;
  src: url(./app/assets/fonts/roboto/Roboto_Light.ttf);
}

@font-face {
  font-family: SitkaRegular;
  src: url(./app/assets/fonts/sitka_banner/Sitka_Regular.ttc);
}

@font-face {
  font-family: SitkaBold;
  src: url(./app/assets/fonts/sitka_banner/Sitka_Bold.ttc);
}




