.TipXDiv{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 25px;
}

.TipWord{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #363737;
}

.XTipModal{
    width: 13px;
    height: 13px;
    cursor: pointer;
}

.TipModalDiv1{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #363737;
    margin-bottom: 12px;
}

.TipModalDiv2{
    display: flex;
    margin-bottom: 16px;
}

.TipModalDiv3{
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.roundImg{
    width: 14px;
    height: 14px;
    margin-right: 6px;
    position: relative;
}

.roundInsideImg{
    position: absolute;
}

.TipModalDiv4{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #363737;
    white-space: nowrap;
    cursor: pointer;
}

.TipModalDiv5{
    display: flex;
    margin-bottom: 16px;
}

.TipModalDiv6{
    width: 80px;
    height: 60px;
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.015em;
    color: #363737;
    padding-top: 17px;
    padding-bottom: 17px;
    background: #FFFFFF;
    border: 1px solid rgba(127, 138, 146, 0.48);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
}

.TipModalDiv7{
    display: flex;
    margin-bottom: 29px;
}

.TipModalDiv8{
    width: 176px;
    height: 80px;
    margin-right: 16px;
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.015em;
    background: #FFFFFF;
    border: 1px solid #888686;
    box-sizing: border-box;
    border-radius: 6px;
    color: rgba(135, 135, 135, 0.7);
    cursor: pointer;
}

.TipModalDiv9 {
   margin:auto;
}

.TipModalLastDiv{
    color: #FFFFFF;
    background: #18A0FB;
    border-radius: 6px;
    margin-right: 0;
    max-width: max-content;
}

.inputBtn{
display: flex;
    align-items: center;
}

button:disabled
button:disabled:hover,
button:disabled:active {
    background: #FFFFFF;
    border: 1px solid #888686;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: not-allowed;
}

.blueButton{
    border: 1px solid #18A0FB;
}

.blueInside{
    background: linear-gradient(119.36deg, #037BF3 3.6%, #03B4F8 81.1%);
}