
.myPageBigDiv{
    background: #E5E5E5;
    border-radius: 6px;
    width: 100%;
    height: 434px;
    margin-bottom: 40px;
    margin-top: 49px;
}









