.popupBoxCreate{
    width: 190px;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(134, 134, 134, 0.25);
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1;
    border-top: 2px solid #03B4F8;
    border-top: 2px solid #03B4F8;
}

.popIconAndTextCreate{
    display: flex;
    align-items: center;
    padding: 17px 15px;
    border-bottom: 1px solid #D6D6D6;
    cursor: pointer;
}


.popText{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-left: 11px;
}
.NFTIconImg{
    width: 20px;
    height: 20px;
}
.createXpoemIconImg {
    width: 22px;
}

.popIconAndTextCreate:hover{
    background: #F1F2F5;
}

.popIconAndTextCreate:first-child:hover{
    border-top:none
}

