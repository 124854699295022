.header{
    background-color:  #FFFFFF;
    box-shadow: 0px 6px 20px rgba(185, 185, 185, 0.38);
}

.headerWhole{
    display: flex;
    justify-content: space-between;
    padding-top:20px;
    padding-bottom: 20px;
    align-items: center;
}

.headerPartOne{
    display: flex;
    flex:1
}

.logoDiv{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    color: #000000;
    margin: auto;

}

.headerSearchDiv{
    max-width: 511px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E5E8EB;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 90px;
    flex: 1;
}


.createHeaderPart{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #0F283D;
    position: relative;
    cursor: pointer;
}

.vectorHeaderPart{
    margin-left: 7px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.headerSearchInput{
    background-color: transparent;
    border: transparent;
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    background: #FFFFFF;
    margin-left: 7px;
    width: 100%;
    outline: 0;
}

.walletIconImg{
    width: 24px;
    height: 24px;
    cursor: pointer;
    cursor: pointer;
}


.searchImg{
    width: 15px;
    height: 15px;
    margin-left: 13px;
}


.headerPartTwo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.connectWalletBtn{
    background: #18A0FB;
    border-radius: 6px;
    cursor: pointer;
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.bellImage{
    width: 20px;
    height: 22px;
    margin-right: 34px;
    cursor: pointer;
}

.messageImage{
    width: 21px;
    height: 22px;
    margin-right: 34px;
    cursor: pointer;
}

.headerPartSecond{
    display: flex;
    position: relative;
}


.personPhoto{
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 34px;
}

.logoImage{
    width: 50px;
    height: 50px;
    margin-right: 11px;
    object-fit: contain;
}

.XpoesyDiv{
    width: 74px;
    height: 35px;
    object-fit: contain;
}

.divForLogo{
    display: flex;
    align-items: center;
    justify-content: center;
}
.loginBtn{
    background: #FFFFFF;
    color: #037BF3;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    border-radius: 6px;
    margin-left: 35px;
    margin-right: 33px;
}





