.repliesBox{
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: 10px;
}

.repliesDiv{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}


.userPicReplyDiv{
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.commentsBigDivReply{

}

.commentsSmallDivReply{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
}

.commentsDivNameReply{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color:  #0F283D;
    margin-right: 16px;
}

.commentsDivDateReply{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #414141;
}

.commentsDescriptionReply{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #414141;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 562px;
}

.likeReplyWholePartReply{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.likeReplyWholePart1Reply{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #03B4F8;
    cursor: pointer;
}

.likeReplyWholePart2Reply{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.likeReplyWholePart2SmallDivReply{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.likeImgCommentsPartBlue{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.likeImgCommentsPart{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.likeReplyPartReply{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #03B4F8;
    cursor: pointer;
}

.likeReplyWholeReply{
    display: flex;
    align-items: center;
}

.edit{
    margin-left: 30px;
}

