.settingAll{
    display: flex;
    margin-top: 100px;
    margin-bottom: 200px;
}

.settingsPart1{
    width: 304px;
    background: #FFFFFF;
    border: 1px solid  #E5E8EB;
    margin-right: 41px;
    max-height: 166px;
}

.settingsPart1Div1{
    display: flex;
    padding: 15px 0 15px 12px;
    border-bottom: 1px solid #E5E8EB;
    cursor: pointer;
}

.settingsPart1Div1:last-child{
    border-bottom: none;
}

.settingsPart1Div1Icon{
    margin-right: 8px;
    width: 24px;
    height: 24px;
}

.settingsPart1Div1Description{
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #0F283D;
}

.settingsBlue{
    border-left:5px solid #03B4F8;
    color: #03B4F8;
}

.blueFont{
    color: #03B4F8;
}


.settingsPart2{
    width: 474px;
    padding: 40px;
    border: 1px solid #E5E8EB;
}


.settingsPart2General{
    font-family: RobotoBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #0F283D;
    margin-bottom: 14px;
}

.userDetails{
    display:flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.labelWord{
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #0F283D;
    margin-bottom: 6px
}

.userDetailsInput{
    padding: 12px 0 12px 14px;
    width:100%;
    max-width:394px;
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #A5A7A8;
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 6px;
}

.bioDetails{
    height: 96px;
}

.cancelCreateSettings{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.cancelBtnSettings{
    color: rgba(3, 123, 243, 0.7);
    background: #FFFFFF;
    border: 1px solid #03B4F8;
    box-sizing: border-box;
    margin-right: 8px;
}
.saveBtnSettings{
    color: #FFFFFF;
    background: linear-gradient(98.28deg, rgba(3, 123, 243, 0.7) 2.98%, rgba(3, 180, 248, 0.7) 79.09%);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
